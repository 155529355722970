import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Offcanvas, Form, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getProductAction, modalToggleAction, ProductStockInAction, updateProductAction, loadingToggleSingleProductAction } from '../../../store/actions/ProductActions';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'
import { searchRack, searchSource } from '../../../services/RackService';
import { dashboardProductStockOverview, getProductStocks } from '../../../services/ProductService';
import { getProductCategoryList, getUnitList } from '../../../services/OtherService';
import ProjectOverviewChart from '../Dashboard/elements/ProjectOverviewChart';
import DataLoader from '../Dashboard/Loader';
import { toast } from 'react-toastify';


const ViewProduct = () => {
    let { id } = useParams();
    const store = useSelector(state => state.products)
    const { selectProduct, productStockRacks, productStockHistory, recommendedStock ,isModalOpen, modalType, isLoadingSingleProduct } = store
    const [categoryList, setCategoryList] = useState([])
    const [unitList, setUnitList] = useState([])
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [stockInValue, setStockInValue] = useState({ quantity: 1, barcode_no: '', rack_id: '', source: '' })
    const [productEditValue, setProductEditValue] = useState({ id: '', name: '', price: '', ucs_code: '', min_threshold: '', max_threshold: '', unit: 'KG', unit_type: 'single', category: '', description: '', sap_description: '', ved_category: '', lead_time: '' })

    const [optionsRack, setOptionsRack] = useState([])
    const [selectedRack, setSelectedRack] = useState(null)
    const [rackInputValue, setRackInputValue] = useState('')
    const [currentOptionPage, setCurrentOptionPage] = useState(1);
    const [hasMoreOption, setHasMoreOption] = useState(true);
    const [selectLoading, setSelectLoading] = useState(false);

    // sorce 
    const [isSourceLoad, setIsSourceLoad] = useState(false)
    const [sourceOption, setSourceOption] = useState([])
    const [sourceValue, setSourceValue] = useState(null)
    const [sourceInputValue, setSourceInputValue] = useState('')

    const [overViewData, setOverViewData] = useState(null)

    const [prodStock, setProdStock] = useState([])


    const pageSize = 3

    const recordsPage = 10;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = productStockRacks.slice(firstIndex, lastIndex);
    const npage = Math.ceil(productStockRacks.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)

    const handleOverviewChange = (view_type) => {
        dashboardProductStockOverview(id, view_type).then((response) => {
            setOverViewData(response.data.data)
        }).catch((error) => {
            console.log(error.response.data.message)
        });
    }


    useEffect(() => {
        dispatch(loadingToggleSingleProductAction(true))
        dispatch(getProductAction(id))
        handleOverviewChange('weekly')
    }, [id])


    const toggleModal = (modal_type, rack_id = null) => {
        if (modal_type === "StockIN") {
            if (isModalOpen) {
                setStockInValue({ quantity: '', barcode_no: '', rack_id: '', source: '' })
                setOptionsRack([])
                setSelectedRack(null)
                setCurrentOptionPage(1);
                setHasMoreOption(true);
                setSelectLoading(false);
                setRackInputValue('')
                setIsSourceLoad(false)
                setSourceOption([])
                setSourceValue(null)
                setSourceInputValue('')
            } else {
                fetchMoreOptions(currentOptionPage, rackInputValue)
            }
        } else if (modal_type === "Edit") {
            if (isModalOpen) {
                setProductEditValue({ id: '', name: '', price: '', ucs_code: '', min_threshold: '', max_threshold: '', unit: '', unit_type: 'single', category: '', description: '', sap_description: '', ved_category: '', lead_time: '' })
                setCategoryList([])
                setUnitList([])
            } else {
                getProductCategoryList().then(resp => {
                    setCategoryList(resp.data.data)
                }).catch(error => {
                    console.log(error)
                })
                getUnitList().then(resp => {
                    setUnitList(resp.data.data)
                }).catch(error => {
                    console.log(error)
                })
                setProductEditValue(
                    {
                        id: id, name: selectProduct.name, price: selectProduct.price,
                        ucs_code: selectProduct.ucs_code, min_threshold: selectProduct.min_threshold,
                        max_threshold: selectProduct.max_threshold, unit: selectProduct.unit.id,
                        unit_type: selectProduct.is_mutli_type_unit ? 'multi' : 'single',
                        category: selectProduct.category ? selectProduct.category.id : '',
                        description: selectProduct.description, sap_description: selectProduct.description_sap,
                        ved_category: selectProduct.ved_category, lead_time: selectProduct.lead_time
                    })
            }
        } else if (modal_type === "RackStock") {
            getProductStocks(id, rack_id).then((resp) => {
                setProdStock(resp.data.data)
            }).catch((error) => console.log("error", error.response.data.message))

        }
        dispatch(modalToggleAction(modal_type))
    }

    async function fetchMoreOptions(page, query = '') {
        if (hasMoreOption) {
            setSelectLoading(true)
            const resp = await searchRack(page, pageSize, query)
            const { total, data } = resp.data
            if (total === (optionsRack.length + data.length)) {
                setHasMoreOption(false)
            } else {
                setCurrentOptionPage(currentOptionPage + 1);
            }
            setOptionsRack(optionsRack.concat(data));
            setSelectLoading(false)
        }
    };

    const handleRcakInputChange = async (value, reason) => {
        if (reason.action === 'input-change') {
            setCurrentOptionPage(1)
            setRackInputValue(value)

            setSelectLoading(true)
            const resp = await searchRack(1, pageSize, value)
            const { total, data } = resp.data
            if (total === (optionsRack.length + data.length)) {
                setHasMoreOption(false)
            } else {
                setCurrentOptionPage(2);
            }
            setOptionsRack([...data]);
            setSelectLoading(false)
        }
    }

    const handleSelectScroll = async () => {
        if (hasMoreOption) {
            setSelectLoading(true)
            const resp = await searchRack(currentOptionPage, pageSize, rackInputValue)
            const { total, data } = resp.data
            if (total === (optionsRack.length + data.length)) {
                setHasMoreOption(false)
            } else {
                setCurrentOptionPage(currentOptionPage + 1);
            }
            setOptionsRack(optionsRack.concat(data));
            setSelectLoading(false)
        }
    };

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function changeCPage(id) {
        setCurrentPage(id);
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }

    const handleStockInChange = (e) => {
        const { name, value } = e.target
        setStockInValue({ ...stockInValue, [name]: value })
    }

    const handleChangeEdit = (e) => {
        const { name, value } = e.target
        setProductEditValue({ ...productEditValue, [name]: value })
    }

    const handleOnSubmitStockIn = async (e) => {
        e.preventDefault();
        if (Object.values(stockInValue).some(itm => itm.length === 0)) {
            toast.error("Required All Mandatory Fields")
            return false
        } else {
            const data = { ...stockInValue, product_id: id }
            dispatch(ProductStockInAction(data))
        }
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        const requiredFieldValue = [productEditValue.id, productEditValue.name, productEditValue.ucs_code, productEditValue.unit, productEditValue.min_threshold]
        if (requiredFieldValue.some(itm => itm.length === 0)) {
            toast.error("Required all mandatory fields")
            return false
        } else {
            const data = { ...productEditValue, product_id: id }
            dispatch(updateProductAction(data))
        }
    }

    const LoadingIndicator = () => {
        return (
            <Spinner color='primary' size={'sm'} delay={0} />
        )
    }

    const handleSourceChange = (data) => {
        try {
            if (data.__isNew__) {
                setSourceOption([{ value: data.value, label: data.label }])
                setSourceValue({ value: data.value, label: data.label })
                setStockInValue({ ...stockInValue, source: data.value })
                setSourceInputValue('')
            } else {
                setSourceOption([{ value: data.value, label: data.label }])
                setSourceValue({ value: data.value, label: data.label })
                setStockInValue({ ...stockInValue, source: data.value })
                setSourceInputValue('')
            }
        } catch (error) {
            console.log(error.response.data.message)
        }
    }


    const handleSourceInputChange = async (value, reason) => {
        if (reason.action === 'input-change') {
            try {
                setIsSourceLoad(true)
                setSourceInputValue(value)
                if (value) {
                    if (value.length > 1) {
                        //useJwt.handleCancelRequest()
                    }
                    const resp = await searchSource(value)
                    const option = resp.data.data
                    setSourceOption([...option])
                    setIsSourceLoad(false)
                } else {
                    //useJwt.handleCancelRequest()
                    setSourceOption([])
                    setIsSourceLoad(false)
                }
            } catch (error) {
                console.log("error", error)
                setSourceOption([])
                setIsSourceLoad(false)
            }
        }
    }


    return (isLoadingSingleProduct ? <DataLoader height={"40rem"} marginTop={'20rem'} /> :
        <>
            {selectProduct != null ? (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3">
                            <div className="card h-auto">
                                <div className="card-body">
                                    <div className="c-profile text-center">
                                        <h4>{selectProduct.name}</h4>
                                    </div>
                                    <div className="c-details">
                                        <ul>
                                            <li>
                                                <span>UCS Code</span>
                                                <p>{selectProduct.ucs_code}</p>
                                            </li>
                                            <li>
                                                <span>Unit</span>
                                                <p>{selectProduct.unit.name}</p>
                                            </li>
                                            <li>
                                                <span>Min Threshold</span>
                                                <p>{selectProduct.min_threshold}</p>
                                            </li>
                                            <li>
                                                <span>Max Threshold</span>
                                                <p>{selectProduct.max_threshold}</p>
                                            </li>
                                            <li>
                                                <span>Material Category</span>
                                                <p>{selectProduct.category?.name}</p>
                                            </li>
                                            <li>
                                                <span>Price</span>
                                                <p>{selectProduct.price}</p>
                                            </li>
                                            <li>
                                                <span>Ved Category</span>
                                                <p>{selectProduct.ved_category}</p>
                                            </li>
                                            <li>
                                                <span>Lead Time</span>
                                                <p>{selectProduct.lead_time} days</p>
                                            </li>
                                            <li>
                                                <span>Unit Type</span>
                                                <p>{selectProduct.is_mutli_type_unit ? "Multi Items" : "Single Item"}</p>
                                            </li>
                                            <li>
                                                <span>Description</span>
                                                <p>{selectProduct.description}</p>
                                            </li>
                                            <li>
                                                <span>Description SAP</span>
                                                <p>{selectProduct.description_sap}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex mt-4 justify-content-end">
                                        <button type="button" onClick={() => toggleModal("Edit")} className="modal-btn btn btn-primary btn-sm  ms-2 ">
                                            <i className="fa-solid fa-pen-to-square me-1" />{" "}
                                            <span>Edit </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-9'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="card h-auto">
                                        <div className="card-header py-3 d-flex align-items-center justify-content-between flex-wrap">
                                            <h3 className="mb-0">Stocks
                                                <span className={`badge ms-1 mb-1 
                                    ${(selectProduct.net_quantity >= selectProduct.min_threshold && selectProduct.net_quantity <= selectProduct.max_threshold) ? 'badge-success' : selectProduct.net_quantity < selectProduct.min_threshold ? 'badge-danger' : 'badge-warning'}`}
                                                    style={{ fontSize: '1rem' }}
                                                >{selectProduct.net_quantity}</span></h3>
                                            <div>
                                                <button type='button' onClick={() => toggleModal('StockIN')} className="btn btn-success btn-sm me-2">Stock IN</button>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive active-projects style-1">
                                                <div id="customer-tblwrapper" className="dataTables_wrapper no-footer">
                                                    <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Rack</th>
                                                                <th>Net Quantity</th>
                                                                <th>View</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {records.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td><Link to={`/rack/${item.id}`}>{item.rack_no}</Link></td>
                                                                    <td>
                                                                        <span>{item.net_quantity}</span>
                                                                    </td>
                                                                    <td>
                                                                        <button type='button' className="btn btn-outline-primary btn-sm" onClick={() => toggleModal('RackStock', item.id)}>View</button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                                                        <div className='dataTables_info'>
                                                            Showing {lastIndex - recordsPage + 1} to{" "}
                                                            {/* {lastIndex}  */}
                                                            {productStockRacks.length < lastIndex ? productStockRacks.length : lastIndex}

                                                            {" "}of {productStockRacks.length} entries
                                                        </div>
                                                        <div
                                                            className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                            id="example2_paginate">
                                                            <Link
                                                                className="paginate_button previous disabled"
                                                                to="#"
                                                                onClick={prePage}
                                                            >
                                                                <i className="fa-solid fa-angle-left" />
                                                            </Link>
                                                            <span>
                                                                {number.map((n, i) => (
                                                                    <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                                        onClick={() => changeCPage(n)}
                                                                    >
                                                                        {n}

                                                                    </Link>
                                                                ))}
                                                            </span>
                                                            <Link
                                                                className="paginate_button next"
                                                                to="#"
                                                                onClick={nextPage}
                                                            >
                                                                <i className="fa-solid fa-angle-right" />
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="card h-auto">
                                        <div className="card-header py-3 d-flex align-items-center justify-content-between flex-wrap">
                                            <h5 className="mb-0">Stockout Recommendations</h5>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive active-projects style-1">
                                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Barcode</th>
                                                            <th>Rack</th>
                                                            <th>Quantity</th>
                                                            <th>Stockin Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {recommendedStock?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.barcode}</td>
                                                                <td>{item.rack}</td>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.created_at}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="card mt-2 h-auto">
                                <div className="card-header py-3 d-flex align-items-center justify-content-between flex-wrap">
                                    <h3 className="mb-0">Stocks History</h3>
                                    <div>
                                        <Link to={`/stock_history/${selectProduct.id}`} type='button' className="btn btn-outline-primary btn-sm ms-2">View More</Link>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1">
                                        <div id="customer-tblwrapper" className="dataTables_wrapper no-footer">
                                            <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Rack</th>
                                                        <th>Quantity</th>
                                                        <th>AMOUNT</th>
                                                        <th>Employee</th>
                                                        <th>DATE</th>
                                                        <th>STATUS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productStockHistory.map((item, index) => (
                                                        <tr key={index}>
                                                            <td><span>{item.stock.rack ? <Link to={`/rack/${item.stock.rack.id}`}>{item.stock.rack.rack_no}</Link> : null}</span></td>
                                                            <td>
                                                                <span>{item.quantity}</span>
                                                            </td>
                                                            <td>
                                                                <span>{item.stock.product.price * item.quantity}</span>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex flex-column">
                                                                    <span>{item?.employee?.name || ""}</span>
                                                                    <small>{item?.purpose || ""}</small>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span>{item.created_at}</span>
                                                            </td>
                                                            <td>
                                                                {item.is_stock_out ? (
                                                                    <span className={`badge light border-0 badge-danger`}>OUT</span>
                                                                ) : (
                                                                    <span className={`badge light border-0 badge-success`}>IN</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {overViewData ?
                                <div className="card mt-2 h-auto">
                                    <div className="card-body p-0">
                                        <ProjectOverviewChart data={overViewData} handleChange={handleOverviewChange} />
                                    </div>
                                </div>
                                : null}
                        </div>


                    </div>

                </div>
            ) : <h3>Invalid Material id {id}</h3>}

            {/* stock in modal */}

            <Modal className="modal fade" id="exampleModal3" show={isModalOpen && modalType === "StockIN"} onHide={() => toggleModal("StockIN")}>
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title">Stock IN</h5>
                        <button type="button" className="btn-close" onClick={() => toggleModal('StockIN')}></button>
                    </div>
                    <form onSubmit={(e) => { handleOnSubmitStockIn(e) }} >
                        <div className="modal-body">
                            <label className="form-label">Rack</label>
                            <Select
                                onMenuScrollToBottom={handleSelectScroll}
                                defaultValue={selectedRack}
                                onChange={(data) => {
                                    setRackInputValue('')
                                    setSelectedRack(data)
                                    setStockInValue({ ...stockInValue, rack_id: data.value })
                                }}
                                isClearable={false}
                                isLoading={selectLoading}
                                inputValue={rackInputValue}
                                options={optionsRack}
                                onInputChange={handleRcakInputChange}
                                className='mb-3'
                                style={{
                                    lineHeight: "40px",
                                    color: "#7e7e7e",
                                    paddingLeft: " 15px",
                                }}
                            />

                            <label className="form-label">Quantity</label>
                            <input type="number" name='quantity' value={stockInValue.quantity} onChange={handleStockInChange} className="form-control mb-3" id="quantity" placeholder="Enter Quantity" />

                            <label className="form-label">Source</label>
                            <CreatableSelect
                                id='tags'
                                isClearable={false}
                                inputValue={sourceInputValue}
                                value={sourceValue}
                                className='react-select mb-3'
                                classNamePrefix='select'
                                placeholder="Search Source"
                                options={sourceOption}
                                isLoading={isSourceLoad}
                                onChange={handleSourceChange}
                                onInputChange={handleSourceInputChange}
                                components={{ LoadingIndicator }}
                            />

                            <label className="form-label">Barcode</label>
                            <input type="text" name='barcode_no' value={stockInValue.barcode_no} onChange={handleStockInChange} className="form-control mb-3" id="barcode_no" placeholder="Scan Barcode" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light btn-sm" onClick={() => toggleModal('StockIN')}>Close</button>
                            <button type="submit" className="btn btn-primary btn-sm">Stock IN</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* rack stock modal */}

            <Modal className="modal fade" id="exampleModal3" show={isModalOpen && modalType === "RackStock"} onHide={() => toggleModal("RackStock")}>
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title">Stocks</h5>
                        <button type="button" className="btn-close" onClick={() => toggleModal('RackStock')}></button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive active-projects style-1">
                            <div id="customer-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Barcode</th>
                                            <th>Quantity</th>
                                            <th>Rack</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {prodStock.map((item, index) => (
                                            <tr key={index}>
                                                <td><span>{item.barcode__barcode_no}</span></td>
                                                <td>
                                                    <span>{item.quantity}</span>
                                                </td>
                                                <td>
                                                    {item.rack__rack_no}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            {/* edit product offcanvas */}
            <Offcanvas show={isModalOpen && modalType === "Edit"} onHide={() => toggleModal("Edit")} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">Edit Product</h5>
                    <button type="button" className="btn-close"
                        onClick={() => toggleModal("Edit")}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Form onSubmit={(e) => handleEditSubmit(e)}>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Material Name <span className="text-danger">*</span></label>
                                        <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                            value={productEditValue.name} name='name' onChange={handleChangeEdit} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="ucs_code" className="form-label">UCS Code <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="ucs_code" placeholder="Enter UCS code"
                                            value={productEditValue.ucs_code} name='ucs_code' onChange={handleChangeEdit} required />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Unit <span className="text-danger">*</span></label>
                                        <select className="default-select form-control" name='unit' value={productEditValue.unit} onChange={handleChangeEdit} required>
                                            <option value="">Select Unit</option>
                                            {unitList.map((item, index) => (
                                                <option value={item.value} key={index}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="min_threshold" className="form-label">Min Threshold <span className="text-danger">*</span></label>
                                        <input type="number" className="form-control" id="min_threshold" placeholder="Enter Min Threshold"
                                            value={productEditValue.min_threshold} name='min_threshold' onChange={handleChangeEdit} required />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="max_threshold" className="form-label">Max Threshold </label>
                                        <input type="number" className="form-control" id="max_threshold" placeholder="Enter Max Threshold"
                                            value={productEditValue.max_threshold} name='max_threshold' onChange={handleChangeEdit} />
                                    </div>

                                    <div className="mb-3">
                                        <label>Unit Item Type</label><br />
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="radio" className="form-check-input" defaultChecked
                                                id="unit_type_single" name="unit_type" checked={productEditValue.unit_type === "single"} value={'single'} onChange={handleChangeEdit} />
                                            <label className="form-check-label" htmlFor="unit_type_single">Single Item</label>
                                        </div>
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="radio" className="form-check-input"
                                                id="unit_type_multi" name="unit_type" checked={productEditValue.unit_type === "multi"} value={'multi'} onChange={handleChangeEdit} />
                                            <label className="form-check-label" htmlFor="unit_type_multi">Multi Items</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-6">
                                    <div className="mb-3">
                                        <label htmlFor="price" className="form-label">Price </label>
                                        <input type="number" className="form-control" id="price" placeholder="Enter Price"
                                            value={productEditValue.price} name='price' onChange={handleChangeEdit} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">VED Category </label>
                                        <select className="default-select form-control" value={productEditValue.ved_category} name='ved_category' onChange={handleChangeEdit}>
                                            <option value="Vital">Vital</option>
                                            <option value="Essential">Essential</option>
                                            <option value="Desirable">Desirable</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="price" className="form-label">Lead Time (in Day)  </label>
                                        <input type="number" className="form-control" id="lead_time" placeholder="Enter Lead Time in day"
                                            value={productEditValue.lead_time} name='lead_time' onChange={handleChangeEdit} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Material Category </label>
                                        <select className="default-select form-control" name='category' value={productEditValue.category} onChange={handleChangeEdit}>
                                            <option value="">Select Category</option>
                                            {categoryList.map((item, index) => (
                                                <option value={item.value} key={index}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Description </label>
                                        <textarea style={{ minHeight: "30px" }} className="form-control" value={productEditValue.description} name='description' onChange={handleChangeEdit}></textarea>
                                    </div>
                                    <div className="col-xl-12 mb-3">
                                        <label className="form-label">description SAP</label>
                                        <textarea rows="2" className="form-control" value={productEditValue.sap_description} name='sap_description' onChange={handleChangeEdit}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <button type="submit" className="btn btn-primary me-1">Update</button>
                                <button type="button" onClick={() => toggleModal("Edit")} className="btn btn-danger light ms-1">Cancel</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Offcanvas>


        </>
    );
};

export default ViewProduct;