import React, { useState, useEffect } from 'react';
import { getProductForecastList } from '../../../services/ProductService';
import { Spinner } from 'react-bootstrap';

const ForecastProducts = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])

    const handleForecastChange = () => {
        setIsLoading(true)
        setData([])
        getProductForecastList().then((response) => {
            const resp_data = response.data.data.data
            setLabels(response.data.data.labels)
            const transformedData = [];
            // Loop through each data array
            for (const dataArray of resp_data) {
                // Loop through each object in the data array
                for (const obj of dataArray) {
                    // Get the product name (key) and its value
                    const productName = Object.keys(obj)[0];
                    const productValue = obj[productName];
                    // Find the entry in transformedData for the current product
                    const existingEntry = transformedData.find(entry => entry.name === productName);
                    if (existingEntry) {
                        // If the product entry exists, push the value to its value array
                        existingEntry.value.push(productValue);
                    } else {
                        // If the product entry doesn't exist, create a new entry
                        transformedData.push({ name: productName, value: [productValue] });
                    }
                }
            }
            setData(transformedData);
            setIsLoading(false)

        }).catch((error) => {
            console.log(error.response.data.message)
            setIsLoading(false)
        });

    }

    useEffect(() => {
        handleForecastChange()
    }, [])

    return (
        <div>
            {isLoading ? <div className='card card-body'>
                <div className='d-flex align-items-center flex-column'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden"></span>
                    </Spinner>
                    <h5>Loading ...</h5>
                </div>
            </div> : <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card" style={{minHeight:'15rem'}}>
                            <div className="card-body p-0">
                                {data.length > 0 ?
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                            <h4 className="heading mb-0">Forecast All Material</h4>
                                            <div className='d-flex align-items-center'>
                                                
                                            </div>
                                        </div>
                                        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                            <table id="empoloyees-tblwrapper" className="table table-bordered table-striped  ItemsCheckboxSec dataTable no-footer mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Material Name</th>
                                                        {labels.map((item, index) => (
                                                            <th key={index}>{item}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            {item.value.map((itm, ind) => (
                                                                <td key={ind}>{itm}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                    : <div className='d-flex align-items-center justify-content-center h-100'>
                                        <h5>No Data Found!</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};



export default ForecastProducts;