import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {ProgressBar} from 'react-bootstrap'

const ProductNetCount = (props) => {
    const { tableData, title } = props
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 5;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = tableData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(tableData.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)

    const perCen = (total_number, current_number) => {
        return (current_number/total_number) * 100
    }

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    return (
        <>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects shorting">

                        <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                            <h4 className="heading mb-0">{title}</h4>
                        </div>

                        <div id="projects-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table id="projects-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map((item, index) => (
                                        <tr key={index}>
                                            <td>{currentPage > 1 ? index + recordsPage + 1 : index + 1}</td>
                                            <td><Link to={`/material/${item.id}`}>{item.name}</Link></td>
                                            <td> 
                                                <div className="tbl-progress-box">
                                                    <ProgressBar 
                                                    variant={(item.quantity/records[0].quantity)*100 > 75 ? "success" : (item.quantity/records[0].quantity)*100 > 50 ? "warning": "danger"} 
                                                    style={{ height: '5px', borderRadius: '4px'}} 
                                                    now={perCen(records[0].quantity, item.quantity) }></ProgressBar>
                                                        <span className="text-primary">{item.quantity}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center">

                                <div className='dataTables_info'>
                                    Showing {lastIndex - recordsPage + 1} to{" "}
                                    {tableData.length < lastIndex ? tableData.length : lastIndex}
                                    {" "}of {tableData.length} entries
                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                    id="example2_paginate"
                                >
                                    <Link
                                        className="paginate_button previous disabled"
                                        to="#"
                                        onClick={prePage}
                                    >
                                        <i className="fa-solid fa-angle-left" />
                                    </Link>
                                    <span>
                                        {number.map((n, i) => (
                                            <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                onClick={() => changeCPage(n)}
                                            >
                                                {n}

                                            </Link>
                                        ))}
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                        to="#"
                                        onClick={nextPage}
                                    >
                                        <i className="fa-solid fa-angle-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ProductNetCount;